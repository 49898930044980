import { Injectable } from '@angular/core';
import {
  isArray,
  isEmpty,
  isNil,
  mapValues,
  max,
  mergeWith,
  omit,
  sum,
} from 'lodash';

import { DATE_FORMAT_MAP } from '../features/commons/stats-revenue/constants/date-format-map';
import {
  CollectedType,
  Stats,
  StatsCollected,
  StatsTypesValue,
} from '../models';

const LAST_KEY_TOTALS = [
  'average_guest_earning',
  'revpar',
  'adr',
  'average_stay_length',
  'confirmed_average_booking_window',
  'cancelled_average_booking_window',
  'counted_average_booking_window',
  'cancellation_ratio',
];

@Injectable({
  providedIn: 'root',
})
export class StatsTotalsHelperService {
  summCollectedArray(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
    collectedToSumm: CollectedType,
  ) {
    const newCollectedToSummCurrent = isArray(
      newCollected?.[collectedToSumm]?.current,
    )
      ? {}
      : { ...newCollected?.[collectedToSumm]?.current };

    const oldCollectedToSumCurrent = isArray(
      oldCollected?.[collectedToSumm]?.current,
    )
      ? {}
      : { ...oldCollected?.[collectedToSumm]?.current };

    const newCollectedToSumPrevious = isArray(
      newCollected?.[collectedToSumm]?.previous,
    )
      ? {}
      : { ...newCollected?.[collectedToSumm]?.previous };

    const oldCollectedToSumPrevious = isArray(
      oldCollected?.[collectedToSumm]?.previous,
    )
      ? {}
      : { ...oldCollected?.[collectedToSumm]?.previous };

    const current = mergeWith(
      newCollectedToSummCurrent,
      oldCollectedToSumCurrent,
      (a, b) => max([a, b]),
    );

    const previous = mergeWith(
      { ...newCollectedToSumPrevious },
      oldCollectedToSumPrevious,
      (a, b) => max([a, b]),
    );

    const data = {
      ...newCollected?.[collectedToSumm],
      current,
      previous,
    };
    return data;
  }

  sumAbsoluteGuestArray(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
  ) {
    return this.summCollectedArray(
      newCollected,
      oldCollected,
      'absolute_guests_array',
    );
  }

  sumAbsoluteChildrenArray(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
  ) {
    return this.summCollectedArray(
      newCollected,
      oldCollected,
      'absolute_children_array',
    );
  }

  sumAbsoluteAdultsArray(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
  ) {
    return this.summCollectedArray(
      newCollected,
      oldCollected,
      'absolute_adults_array',
    );
  }

  sumComputedStayLengths(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
  ) {
    return this.summCollectedArray(
      newCollected,
      oldCollected,
      'computed_stay_lengths',
    );
  }

  sumComputedStayGuests(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
  ) {
    return this.summCollectedArray(
      newCollected,
      oldCollected,
      'computed_stay_guests',
    );
  }

  sumCollectedFunctionMap = {
    computed_stay_guests: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) => this.sumComputedStayGuests(data.newCollected, data.oldCollected),
    computed_stay_lengths: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) => this.sumComputedStayLengths(data.newCollected, data.oldCollected),
    absolute_children_array: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) => this.sumAbsoluteChildrenArray(data.newCollected, data.oldCollected),
    absolute_adults_array: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) => this.sumAbsoluteAdultsArray(data.newCollected, data.oldCollected),
    absolute_guests_array: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) => this.sumAbsoluteGuestArray(data.newCollected, data.oldCollected),
    debug: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
    }) => this.sumCollectedDebug(data.newCollected, data.oldCollected),
    share_percent: (data: { newCollected: Partial<StatsCollected> }) =>
      this.sumCollectedSharePercent(data.newCollected),
    adr: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) =>
      this.sumCollectedAdr(data.newCollected, data.oldCollected, data.statType),
    revpar: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) =>
      this.sumCollectedRevpar(
        data.newCollected,
        data.oldCollected,
        data.statType,
      ),
    average_guest_earning: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) =>
      this.sumCollectedAvarageGuestEarning(
        data.newCollected,
        data.oldCollected,
        data.statType,
      ),
    average_stay_length: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) =>
      this.sumCollectedAverageStayLength(
        data.newCollected,
        data.oldCollected,
        data.statType,
      ),
    cancelled_average_booking_window: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) =>
      this.sumCollectedCancelledAverageBookingWindow(
        data.newCollected,
        data.oldCollected,
        data.statType,
      ),
    confirmed_average_booking_window: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) =>
      this.sumCollectedConfirmedAverageBookingWindow(
        data.newCollected,
        data.oldCollected,
        data.statType,
      ),
    counted_average_booking_window: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) =>
      this.sumCollectedCountedAverageBookingWindow(
        data.newCollected,
        data.oldCollected,
        data.statType,
      ),
    cancellation_ratio: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) =>
      this.sumCollectedCancellationRatio(
        data.newCollected,
        data.oldCollected,
        data.statType,
      ),
    occupation_percentage: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      statType: 'production' | 'revenue';
    }) =>
      this.sumCollectedOccupationPercentage(
        data.newCollected,
        data.oldCollected,
        data.statType,
      ),
    default: (data: {
      newCollected: Partial<StatsCollected>;
      oldCollected: Partial<StatsCollected>;
      key: string;
    }) =>
      this.sumCollectedDefault(data.newCollected, data.oldCollected, data.key),
  };

  constructor() {}

  dateFormatMap: { [partitioning: string]: string } = DATE_FORMAT_MAP;

  recalculateSharePercentForAllChildrenValues(
    values: Stats[],
    parentTotal: StatsTypesValue,
  ): Stats[] {
    return values.map((value) => {
      const { collected } = value;

      const original_current = isNil(collected?.share_percent?.original_current)
        ? collected?.share_percent?.current
        : collected.share_percent?.original_current;

      const original_previous = isNil(
        collected?.share_percent?.original_previous,
      )
        ? collected?.share_percent?.previous
        : collected.share_percent?.original_previous;

      const newSharePercentCurrent =
        +parentTotal?.current * (+original_current / 100);

      const newSharePercentPrevious =
        parentTotal.previous * (+original_previous / 100);

      const newSharePercent: StatsTypesValue = {
        current: newSharePercentCurrent,
        previous: newSharePercentPrevious,
        diff_percent: this.calcDiffPercent(
          newSharePercentCurrent,
          newSharePercentPrevious,
        ),
        original_current,
        original_previous,
      };

      return {
        ...value,
        collected: {
          ...value?.collected,
          share_percent: newSharePercent,
        },
      };
    });
  }

  recalculateSharePercentForAllValues(
    values: Stats[],
    totals: Partial<Stats>,
    keyTotal: 'total_price' | 'gross_amount',
  ): Stats[] {
    return values.map((value) => {
      const { children } = value;

      const newSharePercentCurrent =
        +totals?.collected?.[keyTotal]?.current > 0
          ? (+value?.collected?.[keyTotal]?.current /
              +totals?.collected?.[keyTotal]?.current) *
            100
          : 0;
      const newSharePercentPrevious =
        +totals?.collected?.[keyTotal]?.previous > 0
          ? (+value?.collected?.[keyTotal]?.previous /
              +totals?.collected?.[keyTotal]?.previous) *
            100
          : 0;
      const newSharePercent = {
        current: newSharePercentCurrent,
        previous: newSharePercentPrevious,
        diff_percent: this.calcDiffPercent(
          newSharePercentCurrent,
          newSharePercentPrevious,
        ),
      };

      return {
        ...value,
        collected: { ...value.collected, share_percent: newSharePercent },
        children: value?.children?.length
          ? this.recalculateSharePercentForAllChildrenValues(
              children,
              newSharePercent,
            )
          : value?.children,
      };
    });
  }

  sumCollected(
    oldCollected: Partial<StatsCollected>,
    newCollected: Partial<StatsCollected>,
    statType: 'production' | 'revenue',
  ): Partial<StatsCollected> {
    let newCollectedData = mapValues(
      omit(newCollected, LAST_KEY_TOTALS),
      (val: StatsTypesValue<any>, key: CollectedType) => {
        if (this.sumCollectedFunctionMap[key]) {
          return this.sumCollectedFunctionMap[key]({
            newCollected,
            oldCollected,
          });
        }

        return this.sumCollectedFunctionMap.default({
          newCollected,
          oldCollected,
          key,
        });
      },
    );

    LAST_KEY_TOTALS.forEach((key) => {
      if (!newCollected[key]) {
        return;
      }
      newCollectedData = {
        ...newCollectedData,
        [key]: this.sumCollectedFunctionMap?.[key]({
          oldCollected,
          newCollected,
          statType,
        }),
      };
    });

    return newCollectedData;
  }

  calcDiffPercent(current, previous) {
    return ((current - previous) / previous) * 100;
  }

  sumCollectedAvarageGuestEarning(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
    statType: 'production' | 'revenue',
  ) {
    const keyTotal = statType === 'production' ? 'gross_amount' : 'total_price';

    const grossAmountCurrentSum =
      (oldCollected?.[keyTotal]?.current || 0) +
      (newCollected?.[keyTotal]?.current || 0);

    const absoluteGuestsArrayCurrentSum =
      (this.sumCollectedObj(newCollected, 'absolute_guests_array').current ||
        0) +
      (this.sumCollectedObj(oldCollected, 'absolute_guests_array').current ||
        0);

    const grossAmountPreviousSum =
      (oldCollected?.[keyTotal]?.previous || 0) +
      (newCollected?.[keyTotal]?.previous || 0);

    const absoluteGuestsArrayPreviusSum =
      (this.sumCollectedObj(newCollected, 'absolute_guests_array').previous ||
        0) +
      (this.sumCollectedObj(oldCollected, 'absolute_guests_array').previous ||
        0);

    const current =
      absoluteGuestsArrayCurrentSum > 0
        ? grossAmountCurrentSum / absoluteGuestsArrayCurrentSum
        : 0;

    const previous =
      absoluteGuestsArrayPreviusSum > 0
        ? grossAmountPreviousSum / absoluteGuestsArrayPreviusSum
        : 0;
    return {
      ...newCollected?.average_guest_earning,
      current,
      previous,
      diff_percent: this.calcDiffPercent(current, previous),
    };
  }

  sumCollectedConfirmedAverageBookingWindow(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
    statType: 'production' | 'revenue',
  ) {
    const confirmedBookingWindowCurrentSum =
      (oldCollected?.confirmed_booking_window?.current || 0) +
      (newCollected?.confirmed_booking_window?.current || 0);

    const confirmedBookingCountCurrentSum =
      (oldCollected?.confirmed_booking_count?.current || 0) +
      (newCollected?.confirmed_booking_count?.current || 0);

    const confirmedBookingWindowPreviousSum =
      (oldCollected?.confirmed_booking_window?.previous || 0) +
      (newCollected?.confirmed_booking_window?.previous || 0);

    const confirmedBookingCountPreviousSum =
      (oldCollected?.confirmed_booking_count?.previous || 0) +
      (newCollected?.confirmed_booking_count?.previous || 0);

    const current =
      confirmedBookingWindowCurrentSum > 0
        ? confirmedBookingWindowCurrentSum / confirmedBookingCountCurrentSum
        : 0;

    const previous =
      confirmedBookingWindowPreviousSum > 0
        ? confirmedBookingWindowPreviousSum / confirmedBookingCountPreviousSum
        : 0;
    return {
      ...newCollected?.confirmed_average_booking_window,
      current,
      previous,
      diff_percent: this.calcDiffPercent(current, previous),
    };
  }

  sumCollectedCancelledAverageBookingWindow(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
    statType: 'production' | 'revenue',
  ) {
    const cancelledBookingWindowCurrentSum =
      (oldCollected?.cancelled_booking_window?.current || 0) +
      (newCollected?.cancelled_booking_window?.current || 0);

    const cancelledBookingCountCurrentSum =
      (oldCollected?.cancelled_booking_count?.current || 0) +
      (newCollected?.cancelled_booking_count?.current || 0);

    const cancelledBookingWindowPreviousSum =
      (oldCollected?.cancelled_booking_window?.previous || 0) +
      (newCollected?.cancelled_booking_window?.previous || 0);

    const cancelledBookingCountPreviousSum =
      (oldCollected?.cancelled_booking_count?.previous || 0) +
      (newCollected?.cancelled_booking_count?.previous || 0);

    const current =
      cancelledBookingWindowCurrentSum > 0
        ? cancelledBookingWindowCurrentSum / cancelledBookingCountCurrentSum
        : 0;

    const previous =
      cancelledBookingWindowPreviousSum > 0
        ? cancelledBookingWindowPreviousSum / cancelledBookingCountPreviousSum
        : 0;

    return {
      ...newCollected?.cancelled_average_booking_window,
      current,
      previous,
      diff_percent: this.calcDiffPercent(current, previous),
    };
  }

  sumCollectedCountedAverageBookingWindow(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
    statType: 'production' | 'revenue',
  ) {
    const cancelledBookingWindowCurrentSum =
      (oldCollected?.cancelled_booking_window?.current || 0) +
      (newCollected?.cancelled_booking_count?.current || 0);

    const cancelledBookingCountCurrentSum =
      (oldCollected?.cancelled_booking_count?.current || 0) +
      (newCollected?.cancelled_booking_count?.current || 0);

    const cancelledBookingWindowPreviousSum =
      (oldCollected?.cancelled_booking_window?.previous || 0) +
      (newCollected?.cancelled_booking_window?.previous || 0);

    const cancelledBookingCountPreviousSum =
      (oldCollected?.cancelled_booking_count?.previous || 0) +
      (newCollected?.cancelled_booking_count?.previous || 0);

    const current =
      cancelledBookingWindowCurrentSum > 0
        ? cancelledBookingWindowCurrentSum / cancelledBookingCountCurrentSum
        : 0;

    const previous =
      cancelledBookingWindowPreviousSum > 0
        ? cancelledBookingWindowPreviousSum / cancelledBookingCountPreviousSum
        : 0;

    return {
      ...newCollected?.counted_average_booking_window,
      current,
      previous,
      diff_percent: this.calcDiffPercent(current, previous),
    };
  }

  sumCollectedCancellationRatio(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
    statType: 'production' | 'revenue',
  ) {
    const cancelledBookingCountCurrentSum =
      (oldCollected?.cancelled_reservations?.current || 0) +
      (newCollected?.cancelled_reservations?.current || 0);

    const countedReservationsCurrentSum =
      (oldCollected?.counted_reservations?.current || 0) +
      (newCollected?.counted_reservations?.current || 0);

    const cancelledBookingCountPreviousSum =
      (oldCollected?.cancelled_reservations?.previous || 0) +
      (newCollected?.cancelled_reservations?.previous || 0);

    const countedReservationsPreviousSum =
      (oldCollected?.counted_reservations?.previous || 0) +
      (newCollected?.counted_reservations?.previous || 0);

    const current =
      cancelledBookingCountCurrentSum > 0
        ? (cancelledBookingCountCurrentSum / countedReservationsCurrentSum) *
          100
        : 0;

    const previous =
      cancelledBookingCountPreviousSum > 0
        ? (cancelledBookingCountPreviousSum / countedReservationsPreviousSum) *
          100
        : 0;

    return {
      ...newCollected?.cancellation_ratio,
      current,
      previous,
      diff_percent: this.calcDiffPercent(current, previous),
    };
  }

  sumCollectedObj(
    collected: Partial<StatsCollected<StatsTypesValue<any>>>,
    collectedToSum: CollectedType,
  ) {
    const current = isEmpty(collected?.[collectedToSum]?.current)
      ? 0
      : sum(Object.values(collected?.[collectedToSum]?.current));

    const previous = isEmpty(collected?.[collectedToSum]?.previous)
      ? 0
      : sum(Object.values(collected?.[collectedToSum]?.previous));

    const diff_percent = this.calcDiffPercent(current, previous);

    return {
      ...collected,
      current,
      previous,
      diff_percent,
    };
  }

  sumCollectedAverageStayLength(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
    statType: 'production' | 'revenue',
  ) {
    const computedStayGuestsCurrentSum =
      (this.sumCollectedObj(newCollected, 'computed_stay_guests').current ||
        0) +
      (this.sumCollectedObj(oldCollected, 'computed_stay_guests').current || 0);

    const absoluteGuestsArrayCurrentSum =
      (this.sumCollectedObj(newCollected, 'absolute_guests_array').current ||
        0) +
      (this.sumCollectedObj(oldCollected, 'absolute_guests_array').current ||
        0);

    const computedStayGuestsPreviusSum =
      (this.sumCollectedObj(newCollected, 'computed_stay_guests').previous ||
        0) +
      (this.sumCollectedObj(oldCollected, 'computed_stay_guests').previous ||
        0);

    const absoluteGuestsArrayPreviusSum =
      (this.sumCollectedObj(newCollected, 'absolute_guests_array').previous ||
        0) +
      (this.sumCollectedObj(oldCollected, 'absolute_guests_array').previous ||
        0);

    const current =
      absoluteGuestsArrayCurrentSum > 0
        ? computedStayGuestsCurrentSum / absoluteGuestsArrayCurrentSum
        : 0;

    const previous =
      absoluteGuestsArrayPreviusSum > 0
        ? computedStayGuestsPreviusSum / absoluteGuestsArrayPreviusSum
        : 0;
    return {
      ...newCollected?.average_stay_length,
      current,
      previous,
      diff_percent: this.calcDiffPercent(current, previous),
    };
  }

  sumCollectedOccupationPercentage(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
    statType: 'production' | 'revenue',
  ) {
    const availableNightsCurrentSum =
      (oldCollected?.available_nights?.current || 0) +
      (newCollected?.available_nights?.current || 0);

    const penaltyNightsCurrentSum =
      (oldCollected?.penalty_nights?.current || 0) +
      (newCollected?.penalty_nights?.current || 0);

    const soldNightsCurrentSum =
      (oldCollected?.sold_nights?.current || 0) +
      (newCollected?.sold_nights?.current || 0);

    const availableNightsPreviousSum =
      (oldCollected?.available_nights?.previous || 0) +
      (newCollected?.available_nights?.previous || 0);

    const penaltyNightsPreviousSum =
      (oldCollected?.penalty_nights?.previous || 0) +
      (newCollected?.penalty_nights?.previous || 0);

    const soldNightsPreviousSum =
      (oldCollected?.sold_nights?.previous || 0) +
      (newCollected?.sold_nights?.previous || 0);

    const current =
      soldNightsCurrentSum > 0
        ? ((soldNightsCurrentSum - penaltyNightsCurrentSum) /
            availableNightsCurrentSum) *
          100
        : 0;

    const previous =
      soldNightsPreviousSum > 0
        ? ((soldNightsPreviousSum - penaltyNightsPreviousSum) /
            availableNightsPreviousSum) *
          100
        : 0;

    return {
      ...newCollected?.occupation_percentage,
      current,
      previous,
      diff_percent: this.calcDiffPercent(current, previous),
    };
  }

  sumCollectedSharePercent(newCollected: Partial<StatsCollected>) {
    return {
      ...newCollected?.share_percent,
      current: 100,
      previous: null,
      diff_percent: null,
    };
  }

  sumCollectedAdr(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
    statType?: 'production' | 'revenue',
  ) {
    const stayGrossAmountCurrentSum =
      (oldCollected?.['stay_gross_revenue']?.current || 0) +
      (newCollected?.['stay_gross_revenue']?.current || 0);

    const soldNightsCurrentSum =
      (oldCollected?.sold_nights?.current || 0) +
      (newCollected?.sold_nights?.current || 0);

    const stayGrossAmountPreviousSum =
      (oldCollected?.['stay_gross_revenue']?.previous || 0) +
      (newCollected?.['stay_gross_revenue']?.previous || 0);

    const soldNightsPreviousSum =
      (oldCollected?.sold_nights?.previous || 0) +
      (newCollected?.sold_nights?.previous || 0);

    const current =
      stayGrossAmountCurrentSum > 0
        ? stayGrossAmountCurrentSum / soldNightsCurrentSum
        : 0;

    const previous =
      stayGrossAmountPreviousSum > 0
        ? stayGrossAmountPreviousSum / soldNightsPreviousSum
        : 0;
    return {
      ...newCollected?.adr,
      current,
      previous,
      diff_percent: this.calcDiffPercent(current, previous),
    };
  }

  sumCollectedRevpar(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
    statType?: 'production' | 'revenue',
  ) {
    const stayGrossAmountCurrentSum =
      (oldCollected?.['stay_gross_revenue']?.current || 0) +
      (newCollected?.['stay_gross_revenue']?.current || 0);

    const availableNightsCurrentSum =
      (oldCollected?.available_nights?.current || 0) +
      (newCollected?.available_nights?.current || 0);

    const stayGrossAmountPreviousSum =
      (oldCollected?.['stay_gross_revenue']?.previous || 0) +
      (newCollected?.['stay_gross_revenue']?.previous || 0);

    const availableNightsPreviousSum =
      (oldCollected?.available_nights?.previous || 0) +
      (newCollected?.available_nights?.previous || 0);

    const current =
      stayGrossAmountCurrentSum > 0
        ? stayGrossAmountCurrentSum / availableNightsCurrentSum
        : 0;

    const previous =
      stayGrossAmountPreviousSum > 0
        ? stayGrossAmountPreviousSum / availableNightsPreviousSum
        : 0;
    return {
      ...newCollected?.revpar,
      current,
      previous,
      diff_percent: this.calcDiffPercent(current, previous),
    };
  }

  sumCollectedDefault(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
    key: string,
  ) {
    const current =
      (oldCollected?.[key]?.current || 0) + (newCollected?.[key]?.current || 0);

    const previous =
      (oldCollected?.[key]?.previous || 0) +
      (newCollected?.[key]?.previous || 0);

    const diff_percent = this.calcDiffPercent(current, previous);

    return {
      ...newCollected[key],
      current,
      previous,
      diff_percent,
    };
  }

  sumCollectedDebug(
    newCollected: Partial<StatsCollected>,
    oldCollected: Partial<StatsCollected>,
  ) {
    if (!newCollected?.debug) {
      return {};
    }
    return {
      ...(newCollected?.debug || {}),
      current: {
        invoices: [
          ...(oldCollected?.debug?.current?.invoices || []),
          ...(newCollected?.debug?.current?.invoices || []),
        ],
        reservations: [
          ...(oldCollected?.debug?.current?.reservations || []),
          ...(newCollected?.debug?.current?.reservations || []),
        ],
      },
      previous: {
        invoices: [
          ...(oldCollected?.debug?.previous?.invoices || []),
          ...(newCollected?.debug?.previous?.invoices || []),
        ],
        reservations: [
          ...(oldCollected?.debug?.previous?.reservations || []),
          ...(newCollected?.debug?.previous?.reservations || []),
        ],
      },
    };
  }

  getTotalCollectedFromObj(
    allCollected: Partial<StatsCollected>,
    collected: CollectedType,
    collectedToSum: CollectedType,
    statType: 'production' | 'revenue',
  ): StatsTypesValue {
    const current = isEmpty(allCollected?.[collected]?.current)
      ? 0
      : sum(Object.values(allCollected?.[collected]?.current));

    const previous = isEmpty(allCollected?.[collected]?.previous)
      ? 0
      : sum(Object.values(allCollected?.[collected]?.previous));
    return {
      ...allCollected?.[collectedToSum],
      current,
      previous,
      diff_percent: this.calcDiffPercent(current, previous),
    };
  }

  replaceDefaultCollectedWithComputedCollected(
    collected: Partial<StatsCollected>,
    statType: 'production' | 'revenue',
  ): Partial<StatsCollected> {
    const absolute_guests = this.getTotalCollectedFromObj(
      collected,
      'absolute_guests_array',
      'absolute_guests',
      statType,
    );

    const absolute_adults = this.getTotalCollectedFromObj(
      collected,
      'absolute_adults_array',
      'absolute_adults',
      statType,
    );

    const absolute_children = this.getTotalCollectedFromObj(
      collected,
      'absolute_children_array',
      'absolute_children',
      statType,
    );

    const gross_stay_lengths = this.getTotalCollectedFromObj(
      collected,
      'computed_stay_lengths',
      'gross_stay_lengths',
      statType,
    );

    const gross_stay_guests = this.getTotalCollectedFromObj(
      collected,
      'computed_stay_guests',
      'gross_stay_guests',
      statType,
    );

    return {
      ...collected,
      absolute_guests,
      absolute_adults,
      absolute_children,
      gross_stay_lengths,
      gross_stay_guests,
    };
  }
}
